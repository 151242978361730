function Header() {
  let user = window.sessionStorage.getItem('user');
  let name = JSON.parse(user).name;

  let logout = async function() {

    let id_token = window.sessionStorage.getItem('id_token');
    
    var url = `${process.env.REACT_APP_API_URL}` + '/api/logout';
    let response = await fetch(url, {
      method: "GET",
      credentials: 'include'
    });

    let responseJson = await response.json();
    
    if (id_token) {
      window.location = responseJson.url + '&id_token_hint=' + id_token;
    } else {
      window.location = responseJson.url;
    }

    window.sessionStorage.clear();
  }

  return (
<header class="hero">
  <div class="hero-head">
    <nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item is--brand">
          <img class="navbar-brand-logo" src="/obid.png" alt="OBId Developer Portal"></img>
        </a>
        <button class="button navbar-burger" data-target="navMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <div class="navbar-menu navbar-end" id="navMenu">
        {false && <>
        <a class="navbar-item nav-tag">
          <span class="icon is-small">
            <i class="fa fa-envelope animated"></i>
          </span>
          <span class="tag is-success counter">2</span>
        </a>
        <a class="navbar-item nav-tag">
          <span class="icon is-small">
            <i class="fa fa-bell-o animated"></i>
          </span>
          <span class="tag is-danger counter">6</span>
        </a>
        </>
        }
        <div class="navbar-item has-dropdown is-hoverable">
          
          <a class="navbar-link">
            <figure class="image is-32x32">
              <i class="fa fa-user fa-2x"></i>
            </figure>
            <span>&nbsp;{name}</span>
          </a>


          <div class="navbar-dropdown is-right">
            {/*
            <a class="navbar-item">
              <span class="icon is-small">
                <i class="fa fa-user-o"></i>
              </span>
              &nbsp; Profile
            </a>
            <hr class="navbar-divider" />
            */}
            <a class="navbar-item" onClick={logout}>
              <span class="icon is-small">
                <i class="fa fa-power-off"></i>
              </span>
              &nbsp; Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
  );
}
export default Header;