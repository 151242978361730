import {
  Route,
  Redirect
} from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    window.sessionStorage.getItem('user') !== null
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

export default PrivateRoute;