import logo from './logo.svg';
import Layout from './Layout';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Callback } from './Callback';
import Login from './Login';
import PrivateRoute from './PrivateRoute';

function App() {
  return (      
    <Router>
      <Switch>        
        <Route path="/callback" component={Callback} />
        <Route path="/login" component={Login} />
        <PrivateRoute component={Layout} />
      </Switch>
    </Router>
  );
}

export default App;
