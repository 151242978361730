import {  useState } from "react";

function Login() {
  
  const [inProgress, setInProgess] = useState(false);

  async function login() {
    setInProgess(true);

    try {
      var url = `${process.env.REACT_APP_API_URL}` + '/api/login';
      let response = await fetch(url, {
        method: "GET",
        credentials: 'include'
      });
      let responseJson = await response.json();

      window.location = responseJson.url;
    }
    catch {}
  }

  return (
    <>
      <section class="hero is-medium is-primary is-bold">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              OBId Developer Portal
            </h1>
            <h2 class="subtitle">
              Manage applications and policy
            </h2>
            <button className="button" onClick={login}>
              <span>Login</span>
              {inProgress &&
                <span className="icon is-small">
                  <i className="fas fa-spinner fa-spin" />
                </span>
              }
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
export default Login;