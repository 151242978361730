import React, { Component } from 'react';

export class TextField extends Component {

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }  

  onChange = e => {
    if (this.props.disabled) {
      return;
    }
    this.setState({value: e.target.value});
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    return (
      <>        
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label" htmlFor={this.props.name}>{this.props.label} {this.props.help && <i class="fas fa-info-circle" data-tooltip={this.props.help}></i>}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input className={'input' + (this.props.disabled ? ' readonly' : '')} type="text" placeholder={this.props.placeholder}  name={this.props.name} value={this.state.value} onChange={this.onChange} />            
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}