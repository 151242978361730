import { useEffect, useState } from "react";
import {
  NavLink
} from "react-router-dom";

function Sidebar() {

  const [open, setOpen] = useState(true);

  let activeMenu = '';
  return (
    <aside id="main-sidebar" className={"column is-2 aside" + (open ? " open" : " closed")}>
  <nav class={'menu active-menu--' + activeMenu}>
    {/*
    <p class="menu-label">General</p>
    <ul class="menu-list">
      <li>        
        <NavLink exact activeClassName="is-active" to="/">
          <span class="icon is-small"><i class="fa fa-tachometer-alt"></i></span> <span class="menu-text">Dashboard</span>
        </NavLink>
      </li>
    </ul>*/}
    <p class="menu-label">Administration</p>
    <ul class="menu-list">
      <li>
        <NavLink activeClassName="is-active" to="/apps">
          <span class="icon is-small"><i class="fa fa-pen-square"></i></span> <span class="menu-text">Apps</span>
        </NavLink>
      </li>
      {/*
      <li>
        <a class="<%= isActiveClass('ui-elements') %>" href="<%= data.config.publicPath %>/ui-elements/">
          <span class="icon is-small"><i class="fa fa-desktop"></i></span> <span class="menu-text">UI Elements</span>
        </a>
      </li>
      <li>
        <a class="<%= isActiveClass('tables') %>" href="<%= data.config.publicPath %>/tables/">
          <span class="icon is-small"><i class="fa fa-table"></i></span> <span class="menu-text">Tables</span>
        </a>
      </li>
      <li>
        <a class="<%= isActiveClass('presentations') %>" href="<%= data.config.publicPath %>/presentations/">
          <span class="icon is-small"><i class="fa fa-chart-bar"></i></span>
          <span class="menu-text">Presentations</span>
        </a>
      </li>

      <li class="has-children">
        <a class=""
          ><span class="icon is-small"><i class="fa fa-cog"></i></span> <span class="menu-text">Layouts</span></a
        >
        <ul>
          <li><a>Members</a></li>
          <li><a>Plugins</a></li>
          <li><a>Add a member</a></li>
        </ul>
      </li>*/}
    </ul>
    {/*
    <p class="menu-label">Live On</p>
    <ul class="menu-list">
      <li>
        <a class="<%= isActiveClass('additional') %>" href="<%= data.config.publicPath %>/additional/">
          <span class="icon is-small"><i class="fa fa-bug"></i></span> <span class="menu-text">Additional Pages</span>
        </a>
      </li>
      <li>
        <a class="<%= isActiveClass('extras') %>" href="<%= data.config.publicPath %>/extras/">
          <span class="icon is-small"><i class="fa fa-window-restore"></i></span> <span class="menu-text">Extras</span>
        </a>
      </li>
    </ul>*/}
  </nav>

  <div id="sidebar-toggler" onClick={() => setOpen(!open)}>
    <span class="icon is-small"><i class="fa fa-angle-double-left"></i></span>
  </div>
</aside>
  );
}

export default Sidebar;
