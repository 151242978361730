import React, { Component } from 'react';
import PleaseWait from './PleaseWait';
import {
  Redirect  
} from "react-router-dom";

export class Callback extends Component {

  constructor(props) {
    super(props);

    const { search, hash } = props.location;

    var val;
    if (hash && hash.length > 1) {
      val = hash.substring(1);
    } else {
      val = search;
    }
    const params = new URLSearchParams(val);
    var error = params.get('error');
    var code = params.get('code');
    var state = params.get('state');
    var id_token = params.get('id_token');
    
    this.state = { error, code, state, id_token };
  }
  
  componentDidMount() {        
      
    var self = this;

    if (!this.state.error) {

      var req = 
      {
        code: this.state.code,
        state: this.state.state
      };
  
      var url = `${process.env.REACT_APP_API_URL}` + '/api/logincallback';
      fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        credentials: 'include',
        body: JSON.stringify(req)
      })
        .then(response => response.json())
        .then(function (data) {
          
          if (data.error) {
            self.setState({error: data.error});
            return;
          }

          window.sessionStorage.setItem("user", JSON.stringify(data));
          if (self.state.id_token) {
            window.sessionStorage.setItem("id_token", self.state.id_token);
          }
          window.location = '/apps';
        });
    }
  }

  render() {
    if (this.state.error) {
      return <Redirect to="/login" />;
    }
    
    return <PleaseWait />;
  }
}