import Sidebar from './Sidebar';
import Header from './Header';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './Home';
import { Apps } from './Apps';

function Layout() {
  return (
    <>
    <Header />
    <section className="wrapper">
      <div className="columns">
        <Sidebar />
        <main class="column main">
          <Route path="/apps/:id">
            <Apps />
          </Route>
          <Route path="/apps" exact>
            <Apps />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </main>
      </div>
    </section>
    </>
  );
}
export default Layout;
