import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { LogoField } from './Fields/LogoField';
import { OptionsField } from './Fields/OptionsField';
import { TextField } from './Fields/TextField';
import { UriField } from './Fields/UriField';
import { UriListField } from './Fields/UriListField';

export class AppEditor extends Component {

  constructor(props) {
    super(props);
    this.state = { dirty: false };
  }  

  async componentDidMount() {

    var url = `${process.env.REACT_APP_API_URL}` + '/api/connect/clients/' + this.props.client_id;
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    let responseJson = await response.json();
    if (response.status >= 400) {
      console.log(responseJson);
      return;
    }

    this.setState({metadata: responseJson});
  }

  saveChanges = async e => {
    if (!this.state.metadata.client_id || this.state.metadata.client_id === 'TBD') {
      await this.registerMetadata();
    }
    else {
      await this.updateMetadata();
    }
  }

  registerMetadata = async e => {
    var url = `${process.env.REACT_APP_API_URL}` + '/api/connect/register';
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(this.state.metadata)
    });

    let responseJson = await response.json();
    if (responseJson.error || response.status >= 400) {
      this.setState({error: responseJson.error_description ?? "An unknown error occurred, please try again later.", confirmError: true});
      return;
    }

    this.setState({metadata: responseJson, dirty: false});
    this.props.appListChanged();
  }

  updateMetadata = async e => {
    var url = `${process.env.REACT_APP_API_URL}` + '/api/connect/clients/' + this.props.client_id;
    let response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(this.state.metadata)
    });

    let responseJson = await response.json();
    if (responseJson.error || response.status >= 400) {
      this.setState({error: responseJson.error_description ?? "An unknown error occurred, please try again later.", confirmError: true});
      return;
    }

    this.setState({metadata: responseJson, dirty: false });
    this.props.appListChanged();
  }

  onChange = (field, value) => {    
    console.log(field);
    console.log(value);
    var metadata = {...this.state.metadata};
    metadata[field] = value;    
    console.log(metadata);
    this.setState({metadata, dirty: true });
  }

  deleteApp = async e => {
    var url = `${process.env.REACT_APP_API_URL}` + '/api/connect/clients/' + this.props.client_id;
    let response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache"      
    });

    let responseJson = await response.json();
    if (response.status >= 400) {
      console.log(responseJson);
      return;
    }

    this.setState({metadata: responseJson});
    this.props.appListChanged();
  }

  renderConfirmDelete() {

    return (
    <div className={'modal' + (this.state.confirmDeleteModal ? ' is-active': '')}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Delete application?</p>
          <button className="delete" aria-label="close" onClick={() => this.setState({confirmDeleteModal: false})}></button>
        </header>
        <section className="modal-card-body">
          <div className="container">
            <p>Please confirm you want to delete this application by entering the client name.</p>            
            <br />
            <TextField label="Client Name" name="client_name" value={this.state.delete_client_name} onChange={text => this.setState({delete_client_name: text})} />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" disabled={this.state.delete_client_name !== this.state.metadata.client_name} onClick={this.deleteApp}>Confirm Deletion</button>
          <button className="button" onClick={() => this.setState({confirmDeleteModal: false})}>Cancel</button>
        </footer>
      </div>
    </div>);
  }

  renderConfirmError() {

    return (
    <div className={'modal' + (this.state.confirmError ? ' is-active': '')}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Unable to save metadata</p>
          <button className="delete" aria-label="close" onClick={() => this.setState({confirmDeleteModal: false})}></button>
        </header>
        <section className="modal-card-body">
          <div className="container">
            <p>The following error occurred when attempting to save the client metadata:</p>            
            <p>{this.state.error}</p>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={() => this.setState({confirmError: false})}>OK</button>
        </footer>
      </div>
    </div>);
  }

  render() {
    if (!this.state.metadata) {
      return (<></>);
    }

    let newItem = this.state.metadata.client_id === 'TBD';

    return (
      <>
        <OptionsField label="Application Type" name="application_type" value={this.state.metadata.application_type} values={[{label:'Web', value: 'web'}, {label:'Native', value: 'native'}]} onChange={value => this.onChange('application_type', value)} />
        <TextField disabled="true" label="Client Id" name="client_id" value={this.state.metadata.client_id} onChange={value => this.onChange('client_id', value)} />
        <TextField disabled="true" label="Client Secret" name="client_secret" value={this.state.metadata.client_secret} onChange={value => this.onChange('client_secret', value)} />
        <TextField label="Client Name" name="client_name" value={this.state.metadata.client_name} onChange={value => this.onChange('client_name', value)} />
        <UriField label="Client Uri" name="client_uri" value={this.state.metadata.client_uri} onChange={value => this.onChange('client_uri', value)} />
        <UriListField label="Redirect Uris" name="redirect_uris" value={this.state.metadata.redirect_uris} onChange={value => this.onChange('redirect_uris', value)} />
        <LogoField label="Logo Uri" name="logo_uri" value={this.state.metadata.logo_uri} onChange={value => this.onChange('logo_uri', value)} /> 
        <UriListField label="Logout redirect url" name="post_logout_redirect_uris" value={this.state.metadata.post_logout_redirect_uris} onChange={value => this.onChange('post_logout_redirect_uris', value)} />        
        <Collapsible trigger="Signing and Encryption (optional)">               
          <UriField label="JWKS Uri" name="jwks_uri" value={this.state.metadata.jwks_uri} onChange={value => this.onChange('jwks_uri', value)} helpurl="https://www.google.com"/>
          <OptionsField label="Request object signing algorithm" name="request_object_signing_alg" value={this.state.metadata.request_object_signing_alg} values={[{label:'RS256', value: 'RS256'}, {label:'PS256', value: 'PS256'}]} onChange={value => this.onChange('request_object_signing_alg', value)} />
          <OptionsField label="ID Token signing algorithm" name="id_token_signed_response_alg" value={this.state.metadata.id_token_signed_response_alg ?? 'RS256'} values={[{label:'RS256', value: 'RS256'}, {label:'PS256', value: 'PS256'}]} onChange={value => this.onChange('id_token_signed_response_alg', value)} />          
          <OptionsField label="ID Token key encryption algorithm" name="id_token_encrypted_response_alg" value={this.state.metadata.id_token_encrypted_response_alg ?? 'none' } values={[{label:'None', value: 'none'}, {label:'RSA-OAEP', value: 'RSA-OAEP'}]} onChange={value => this.onChange('id_token_encrypted_response_alg', value)} />
          <OptionsField label="ID Token content encryption algorithm" name="id_token_encrypted_response_enc" value={this.state.metadata.id_token_encrypted_response_enc ?? 'A128CBC-HS256' } values={[{label:'A128CBC-HS256', value: 'A128CBC-HS256'}, {label:'A256CBC-HS512', value: 'A256CBC-HS512'}, {label:'A128GCM', value: 'A128GCM'}, {label:'A256GCM', value: 'A256GCM'}]} onChange={value => this.onChange('id_token_encrypted_response_enc', value)} />
        </Collapsible>
        <hr />
        <div className="field is-grouped">
          <p className="control">
            <button className="button is-success" onClick={this.saveChanges} disabled={!this.state.dirty}>{newItem ? 'Create application' : 'Save changes'}</button>
          </p>
          {!newItem &&
          <p className="control">
            <button className="button is-danger" onClick={e => this.setState({confirmDeleteModal: true})}>Delete app...</button>
          </p>
          }
        </div>
        <hr />
        {this.renderConfirmDelete()}
        {this.renderConfirmError()}
      </>
    );
  }
}