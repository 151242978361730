import React, { Component } from 'react';

export class UriListField extends Component {

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  
  addItem = e => {
    let canAdd = this.state.value.every(str => str !== null && str.match(/^ *$/) === null);
    if (!canAdd) return;

    var list = this.state.value.concat('');
    this.setState({value: list});
  }

  deleteItem = index => {
    var list = [...this.state.value];
    list.splice(index, 1);
    this.setState({value: list});
    
    if (this.props.onChange) {
      this.props.onChange(list);
    }
  }

  onChangeValue = (e, index) => {
    var list = [...this.state.value];

    list[index] = e.target.value;
    this.setState({value: list});
    
    if (this.props.onChange) {
      this.props.onChange(list);
    }
  }

  render() {
    let canDeleteItems = this.state.value.length > 1;// && this.state.value.every(str => str !== null && str.match(/^ *$/) === null);

    return (
    <>
      {this.state.value.map((item, index) => {
        return (
          <div className="field is-horizontal"  key={index} >
            <div className="field-label is-normal">
              {index === 0 &&
              <label className="label" htmlFor={this.props.name}>{this.props.label}</label>
              }
            </div>
            <div className="field-body">  
              <div className={"field" + (canDeleteItems ? " has-addons" : "")}>
                <div className="control is-expanded">
                  <input className="input" type="text" value={item} onChange={e => this.onChangeValue(e, index)} />
                </div>
                {canDeleteItems &&
                    <div class="control">
                      <button type="submit" class="button is-danger" onClick={e => this.deleteItem(index)}>
                        <span class="icon is-right">
                          <i class="fas fa-times"></i>
                        </span>
                      </button>
                    </div>
                  }
              </div>
            </div>
          </div>
        );
      })}      
      <div className="field is-horizontal">
        <div className="field-label is-normal">
        </div>
        <div className="field-body">        
          <button className="button is-success" onClick={this.addItem}>+</button>
        </div>
      </div>
    </>
    );
  }
}