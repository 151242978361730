import React, { Component } from 'react';

export class OptionsField extends Component {

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  onChange = e => {
    if (!e.target.checked) {
      return;
    }
    this.setState({value: e.target.value});
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    return (
      <>        
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{this.props.label}</label>
          </div>
          <div className="field-body">
            <div className="field">
              {this.props.values.map((val, idx) => {
                var checked = this.state.value === val.value;
                return (
                  <>
                    <input className="is-checkradio" id={this.props.name + '_' + idx} type="radio" name={this.props.name} value={val.value} checked={checked} onChange={this.onChange}></input>
                    <label for={this.props.name + '_' + idx}>{val.label}</label>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
