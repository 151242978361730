import React, { Component } from 'react';
import { AppEditor } from './AppEditor';
import { Link, withRouter, Redirect } from "react-router-dom";

class Apps1 extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    console.log(props);
  }

  async componentDidMount() {
    await this.onAppListChanged();
    if ((!this.state.apps || !this.state.apps.length) && this.props.match.params.id === 'TBD') {      
      var apps = [];
      apps.push({ client_name: 'New app', client_id: 'TBD', client_secret: 'TBD', application_type: 'web', redirect_uris: ['https://localhost/callback']});    
      this.setState({apps: apps});      
    }
  }

  addNewApp = e => {
    var apps = [...this.state.apps];
    if (apps.length > 0) {
      apps.push({ client_name: 'New app', client_id: 'TBD', client_secret: 'TBD', application_type: 'web', redirect_uris: ['https://localhost/callback']});    
      this.setState({apps: apps});      
    }
    this.props.history.push("/apps/TBD");
  }

  onAppListChanged = async e => {
    var url = `${process.env.REACT_APP_API_URL}` + '/api/connect/clients';
    let response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    if (response.status >= 400) {
      this.props.history.push("/login");
      return;
    }

    let responseJson = await response.json();

    var apps = responseJson;
    if (!apps.length && this.props.match.params.id && this.props.match.params.id !== 'TBD') {
      this.props.history.push("/apps")
    }
    else {
      this.setState({apps: apps});
    }
    
  }

  render() {
    if (!this.state.apps) {
      return <></>;
    }

    if (!this.state.apps.length) {
      return (<>
        <p>No apps currently exist.</p><hr /><p><button className="button is-primary" onClick={this.addNewApp}>Add new app</button></p>
      </>);
    }

    if (!this.props.match.params.id) {
      console.log('/apps/' + this.state.apps[0].client_id);
      return <Redirect to={'/apps/' + this.state.apps[0].client_id} />
    }

    var client_id = this.props.match.params.id;
    var selectedApp = this.state.apps.find(app => app.client_id == client_id);

    if (!selectedApp) { 
      console.log('here')     ;
      return <Redirect to={'/apps/' + this.state.apps[0].client_id} />
    }

    return (
      <>
        <div className="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu2">
              <span><strong>{selectedApp.client_name}</strong> ({selectedApp.client_id})</span>
              <span class="icon is-small">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu2" role="menu">
            <div class="dropdown-content">
            {this.state.apps.map((item, idx) => {
                return (<>
              <div class="dropdown-item">
                <Link to={"/apps/" + item.client_id}>
                <p><strong>{item.client_name}</strong> ({item.client_id})</p>
                </Link>
              </div>
              <hr class="dropdown-divider" />
              </>
            )})}            
              <div class="dropdown-item">
                <button className="button is-primary" onClick={this.addNewApp}>Add new app</button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        
        <AppEditor key={this.props.match.params.id} client_id={this.props.match.params.id} appListChanged={this.onAppListChanged} />
      </>
    );
  }
}

export const Apps = withRouter(Apps1);