import React from 'react';
export default props => props.logo ? (
  <div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
  <div className="pleasewaitwithlogo">
    <h1 className="title">{props.title ? props.title : 'Please wait...'}</h1>
    <div className="sk-stuff">
      <img src={props.logo}  width="120px" height="auto" />
      <div class="sk-circle-fade">
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
      </div>
    </div>
    <h2 className="subtitle">{props.subtitle}</h2>
  </div></div></div></div>
  ) : (
  <div className="pleasewait">
    <h1 className="title">{props.title ? props.title : 'Please wait...'}</h1>
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  </div>
);
